import React from 'react';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import { Input } from '@dealroadshow/uikit/core/components/Input';
import { useMxValidation } from './useMxValidation';

const EmailInput = ({
  showIcon = true,
  enableMxValidation = false,
  meta = {},
  input,
  ...props
}) => {
  const value = input?.value ?? props.value;
  const isMxValid = useMxValidation({
    enable: enableMxValidation,
    value,
  });

  return (
    <Input
      type="email"
      icon={ showIcon ? IconEmail : undefined }
      input={ input }
      { ...props }
      meta={ {
        ...meta,
        ...(enableMxValidation && !isMxValid && {
          warning: 'Please verify your email address',
          touched: true,
        }),
      } }
    />
  );
};

export default EmailInput;
